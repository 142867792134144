import {service, qrCodeRootUrl} from './request.js';
// import qs from 'qs';

// 获取国家地区列表
export function getCountryList() {
	return service({
		url: '/h5/idd/code',
		data: {}
	})
}

// 上传护照或通行证
export function uploadPassport(base64, type) {
	return service({
		url: '/h5/idCard/image/upload/base64?idCardType=' + type,
		data: base64,
    isShowNotify: true,
	})
}

// 问卷调查结果保存
export function saveQuestion(data) {
	return service({
		url: '/h5/question/commit',
		data
	})
}

// 校验是否已报名成功
export function voucherState(unionid) {
	return service({
		url: '/h5/voucher/state',
		data: {}
	})
}

// 获取会籍卡内二维码内容
export function getMemberCardQrCode(unionid = '') {
	return service({
		url: qrCodeRootUrl + '/qrCode/getUrl',
		data: {
			unionid
		}
	})
}

// 获取微信授权签名
// export const getSign = (query={}) => {
//   return service({
//     url: '/h5/tencent/sign',
//     data: query
//   })
// }

// 校验二维码
export function indexWx(data) {
	return service({
		url: '/h5/index/wx',
		data
	})
}

// 获取手机验证码
export function getCodeByPhone(data) {
	return service({
		url: '/h5/phone/send',
		data
	})
}

// 登陆
export function phoneVerifyCode(data) {
	return service({
		url: '/h5/phone/verify/code',
		data
	})
}

// 重新实名认证
export function idCardVerify(data) {
	return service({
		url: '/h5/idCard/verify',
		data,
    isShowNotify: true,
	})
}

// 初次实名认证
export function mergeVerify(data) {
	return service({
		url: '/h5/merge/verify',
		data,
    isShowNotify: true,
	})
}

// 行业选择提交
export function commitIndustrySelect(data) {
	return service({
		url: '/h5/trade/commit',
		data
	})
}

// 个人信息提交
export function setUserInfo(data) {
	return service({
		url: '/h5/enroll/setUserInfo',
		data
	})
}
// 获取个人信息
export function getUserInfo(data) {
	return service({
		url: '/h5/enroll/getUserInfo',
		data: {}
	})
}

// 上传名片正面图片
export function uploadCardFront(data) {
	return service({
		url: '/h5/card/frontUpload/ocrBase64',
		data,
    isShowNotify: true,
	})
}
// 上传名片反面图片
export function uploadCardBacd(data) {
	return service({
		url: '/h5/card/backUpload/ocrBase64',
		data,
    isShowNotify: true,
	})
}
// 名片信息提交
export function commitCard(unionid) {
	return service({
		url: '/h5/card/nextNode',
		data: {
			unionid
		}
	})
}

// 发送微信公众号消息
export function sendTemplateMsg(unionid) {
	return service({
		url: '/h5/template/msg/send',
		data: {
			unionid
		}
	})
}

// 校验是否已支付
export function validatePay(unionid) {
	return service({
		url: '/h5/pay/validate',
		data: {
			unionid
		}
	})
}

// 获取会籍卡信息
export function voucherInit(data) {
	return service({
		url: '/h5/voucher/init',
		data
	})
}

// 获取实名验证时的信息(短信链接进入页面时触发)
export function idCardInit(unionid) {
	return service({
		url: '/h5/idCard/init',
    isShowNotify: true,
		data: {
			unionid
		}
	})
}
// 获取实名验证时的信息(获取回填数据)
export function mergeInit(data) {
	return service({
		url: '/h5/merge/init',
    isShowNotify: true,
    data
	})
}

// 登录 - 核验
export function checkLogin(data) {
	return service({
		url: qrCodeRootUrl + '/qrCode/login',
		data
	})
}
// 获取信息 - 核验
export function getCheckInfo(data) {
	return service({
		url: qrCodeRootUrl + '/qrCode/getUserInfo',
    data
	})
}
// 上传图片 - 核验
export function updateCheckImg(data) {
	return service({
		url: qrCodeRootUrl + '/qrCode/rephotograph',
		data
	})
}

// 获取防疫检测结果
export function getEPSInfo(unionid = '') {
	return service({
		url: '/h5/acid/info',
		// data: {
		// 	unionid
		// }
	})
}