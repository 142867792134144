import axios from 'axios';
import {uuid} from '@/utils/utils.js';
import CryptoJS from '@/utils/aes_util.js';
import {Toast, Notify} from 'vant';

// 开发环境===============================================
// const dev = 'http://172.20.25.142:9090'; // 通用API请求地址
const dev = 'https://reg36test.szcreativeweek.com'; // 通用API请求地址
// const dev = 'https://reg39.szcreativeweek.com'; // 通用API请求地址 ----- 正式
// const devQrCodeUrl = 'http://172.20.25.142:19093'; //  会籍卡页二维码请求地址
// const devQrCodeUrl = 'http://192.168.20.104:19093'; //  二维码、现场查验系统
const devQrCodeUrl = 'https://qr36test.szcreativeweek.com'; //  二维码、现场查验系统

// 生产环境===============================================
// const prod = 'https://reg36test.szcreativeweek.com'; // 通用API请求地址 ----- 测试
// const prodQrCodeUrl = 'https://qr36test.szcreativeweek.com'; // 会籍卡二维码、现场查验系统 ----- 测试
const prod = 'https://reg39.szcreativeweek.com'; // 通用API请求地址 ----- 正式
const prodQrCodeUrl = 'https://qr.szcreativeweek.com'; // 会籍卡二维码、现场查验系统 ----- 正式

// 是否生产环境
const isProduction = process.env.NODE_ENV === 'production';

// =======================================================
const timeoutTime = 10000; // 接口请求超时时间设置(毫秒ms)

// 会籍卡(登记成功)页二维码、现场查验系统 请求根路径
const qrCodeRootUrl = isProduction ? prodQrCodeUrl : devQrCodeUrl;

const service = axios.create({
	baseURL: isProduction ? prod : dev, // 接口请求默认根路径
	method: 'post',
	timeout: timeoutTime,
	headers: {
		'content-type': 'application/json;charset=UTF-8', // JSON
		// 'content-type': 'application/x-www-form-urlencoded;charset=UTF-8', // formData
		// 'content-type': 'multipart/form-data', // formData - 文件流上传
	},
})

// request 请求拦截器
service.interceptors.request.use(config => {
	// if (!config.headers.hasOwnProperty('content-type')) {}

  if (!config.headers.hasOwnProperty('language')) {
    if (config.url.startsWith('https://qr')) { // 以https://qr开头的接口路径，设置为中文
      config.headers['language'] = 0;
    } else {
      config.headers['language'] = localStorage.getItem('lang') == 'zh' ? 0 : 1;
    }
  }
	config.headers.unionid = localStorage.getItem('unionid') || uuid(); // 用户unionid

	// 非上传base64位图片时, 加密请求参数data
	if (!config.url.includes('/base64') && !config.url.includes('/ocrBase64')) {
		config.data = CryptoJS.AesEncrypt(JSON.stringify(config.data || {}))
	}
	
	return config;
}, error => {
	console.log('requestError: ', error);
	return Promise.reject(error);
})

// response 响应拦截器
service.interceptors.response.use(response => {
	let {status, config, data: res} = response;
	if (status === 200) {
		if (res.code == 200) {

			// 对非图片上传的接口返回值进行解密 (接口路径包含 “/base64” 或 “/ocrBase64” 时, 或者获取会籍卡二维码时)
			if (!config.url.includes('/base64') && !config.url.includes('/ocrBase64') && !config.url.includes('/qrCode/getUrl') ) {
				res.data && (res.data = JSON.parse(CryptoJS.AesDecrypt(res.data)))
			}
      
      !isProduction && console.log(config.url, ': ', res.data);
			
			return res
		} else {
			// 根据code弹出错误信息
			// const errMsgConfig = require('@/view/config/errMsgSet.js')
			// Toast(errMsgConfig[localStorage.getItem('lang') || 'zh'][res.code]);
			
			// ERR_MSG_50137 为会籍卡页面二维码刷新过于频繁时的返回提示code
			// if (res.code === 'ERR_MSG_50137') {
			// 	return false
			// }
      
      
      // 曾用微信小程序报名
      if (res.code === 'ERR_40009') {
        return res
      } else if (res.code === 'ERR_MSG_50137') {
        // ERR_MSG_50137: 请勿频繁请求，请稍后重试
        // Toast(res.message);
        return Promise.reject(res);
      } else {
        if (config.isShowNotify) {
          Notify({ message: res.message, duration: 3000 });
        } else {
          Toast(res.message);
        }
				return Promise.reject(res);
			}
		}
		
	} else {
		return Promise.reject(new Error());
	}
}, error => {
	console.log('error: ', error);
	console.log('errorResponse: ', error.response); // {data: {…}, status: 500, statusText: 'Internal Server Error', headers: {…}, config: {…}, …}
  
  // 会籍卡页面获取二维码内容异常时, 生成指定的二维码内容: 'UNID-' + unionid + '|' +时间戳
  if (error.config.url.includes('/qrCode/getUrl')) {
    
    let res = {
      code: 200,
      data: 'UNID-' + localStorage.getItem('unionid') + '|' + Date.now(),
    }
    return res
    
  } else { // 非会籍卡页面请求二维码时
    
    if (error.message === `timeout of ${timeoutTime}ms exceeded`) {
      Toast('网络请求超时');
    } 
    
    if (error.message === 'Network Error') {
      Toast('网络异常');
    } 
    
    if (error.response && error.response.status) { // 如: Internal Server Error
      const errorStatusCode = require('@/view/config/errorStatusCode.js');
      // const errorTips = errorStatusCode[localStorage.getItem('lang') || 'zh'][error.response.status];
      const errorTips = errorStatusCode.default.zh[error.response.status];
      console.log(errorTips);
      errorTips && Toast(errorTips);
    }
    
  }

	// return false;
	return Promise.reject(error);
})

export {
	service, // axios实例
	qrCodeRootUrl, // 会籍卡(登记成功)页二维码请求根路径
};
