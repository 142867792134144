/**
 * 将时间解析为字符串
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string}
 */
export function parseTime(time, cFormat) {
	if (arguments.length === 0) {
		return null
	}
	const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
	let date
	if (typeof time === 'object') {
		date = time
	} else {
		if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
			time = parseInt(time)
		}
		if ((typeof time === 'number') && (time.toString().length === 10)) {
			time = time * 1000
		}
		date = new Date(time)
	}
	const formatObj = {
		y: date.getFullYear(),
		m: date.getMonth() + 1,
		d: date.getDate(),
		h: date.getHours(),
		i: date.getMinutes(),
		s: date.getSeconds(),
		a: date.getDay()
	}
	const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
		let value = formatObj[key]
		// Note: getDay() returns 0 on Sunday
		if (key === 'a') {
			return ['日', '一', '二', '三', '四', '五', '六'][value]
		}
		if (result.length > 0 && value < 10) {
			value = '0' + value
		}
		return value || 0
	})
	return time_str
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
	if (('' + time).length === 10) {
		time = parseInt(time) * 1000
	} else {
		time = +time
	}
	const d = new Date(time)
	const now = Date.now()

	const diff = (now - d) / 1000

	if (diff < 30) {
		return '刚刚'
	} else if (diff < 3600) {
		// less 1 hour
		return Math.ceil(diff / 60) + '分钟前'
	} else if (diff < 3600 * 24) {
		return Math.ceil(diff / 3600) + '小时前'
	} else if (diff < 3600 * 24 * 2) {
		return '1天前'
	}
	if (option) {
		return parseTime(time, option)
	} else {
		return (
			d.getMonth() +
			1 +
			'月' +
			d.getDate() +
			'日' +
			d.getHours() +
			'时' +
			d.getMinutes() +
			'分'
		)
	}
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function getQueryObject(url) {
	url = url == null ? window.location.href : url
	const search = url.substring(url.lastIndexOf('?') + 1)
	const obj = {}
	const reg = /([^?&=]+)=([^?&=]*)/g
	search.replace(reg, (rs, $1, $2) => {
		const name = decodeURIComponent($1)
		let val = decodeURIComponent($2)
		val = String(val)
		obj[name] = val
		return rs
	})
	return obj
}

/**
 * @param {string} input value
 * @returns {number} output value
 */
export function byteLength(str) {
	// returns the byte length of an utf8 string
	let s = str.length
	for (var i = str.length - 1; i >= 0; i--) {
		const code = str.charCodeAt(i)
		if (code > 0x7f && code <= 0x7ff) s++
		else if (code > 0x7ff && code <= 0xffff) s += 2
		if (code >= 0xDC00 && code <= 0xDFFF) i--
	}
	return s
}

/**
 * @param {Array} actual
 * @returns {Array}
 */
export function cleanArray(actual) {
	const newArray = []
	for (let i = 0; i < actual.length; i++) {
		if (actual[i]) {
			newArray.push(actual[i])
		}
	}
	return newArray
}

/**
 * @param {Object} json
 * @returns {Array}
 */
export function param(json) {
	if (!json) return ''
	return cleanArray(
		Object.keys(json).map(key => {
			if (json[key] === undefined) return ''
			return encodeURIComponent(key) + '=' + encodeURIComponent(json[key])
		})
	).join('&')
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
	const search = url.split('?')[1]
	if (!search) {
		return {}
	}
	return JSON.parse(
		'{"' +
		decodeURIComponent(search)
		.replace(/"/g, '\\"')
		.replace(/&/g, '","')
		.replace(/=/g, '":"')
		.replace(/\+/g, ' ') +
		'"}'
	)
}

/**
 * @param {string} val
 * @returns {string}
 */
export function html2Text(val) {
	const div = document.createElement('div')
	div.innerHTML = val
	return div.textContent || div.innerText
}


/**
 * @param {HTMLElement} element
 * @param {string} className
 */
export function toggleClass(element, className) {
	if (!element || !className) {
		return
	}
	let classString = element.className
	const nameIndex = classString.indexOf(className)
	if (nameIndex === -1) {
		classString += '' + className
	} else {
		classString =
			classString.substr(0, nameIndex) +
			classString.substr(nameIndex + className.length)
	}
	element.className = classString
}

/**
 * @param {string} type
 * @returns {Date}
 */
export function getTime(type) {
	if (type === 'start') {
		return new Date().getTime() - 3600 * 1000 * 24 * 90
	} else {
		return new Date(new Date().toDateString())
	}
}

// 防抖：防止重复点击触发事件 --- VUE
export function debounce(fnName, time) {
	let timeout = null
	return function() {
		if (timeout) {
			clearTimeout(timeout)
		}
		timeout = setTimeout(() => {
			this[fnName]()
		}, time);
	}
}

/**
 * 这只是深拷贝的一个简单版本，有很多缺陷
 * 如果想使用完美的深拷贝，可以使用lodash的.cloneDeep
 * @param {Object} obj
 * @returns {Object}
 */
export function deepClone(data) {
	const result = Array.isArray(data) ? [] : {};
	for (var key in data) {
		if (data.hasOwnProperty(key)) {
			if (data[key] !== null && typeof data[key] === 'object') {
				result[key] = deepClone(data[key]); //递归复制
			} else {
				result[key] = data[key];
			}
		}
	}
	return result;
}

/**
 * 检查元素是否具有指定class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */
export function hasClass(ele, cls) {
	return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'))
}

/**
 * 向元素添加类
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function addClass(ele, cls) {
	if (!hasClass(ele, cls)) ele.className += ' ' + cls
}

/**
 * 从元素中删除类
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function removeClass(ele, cls) {
	if (hasClass(ele, cls)) {
		const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)')
		ele.className = ele.className.replace(reg, ' ')
	}
}

//生成36位全局唯一标识符
export function uuid() {
	var s = [];
	var hexDigits = "0123456789abcdef";
	// 标识符长度36位
	for (var i = 0; i < 36; i++) {
		s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
	}
	s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
	s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
	s[8] = s[13] = s[18] = s[23] = "-";

	return s.join("");
}

/*
 ** randomWord 产生任意长度随机字母数字组合
 ** randomFlag-是否任意长度 min-任意长度最小位[固定位数] max-任意长度最大位
 ** 生成3-32位随机串：randomWord(true, 3, 32)
 ** 生成43位随机串：randomWord(false, 43)
 */
export function randomWord(randomFlag, min, max) {
	var str = "",
		range = min,
		pos = '',
		arr = '_-0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
	// 随机产生
	if (randomFlag) {
		range = Math.round(Math.random() * (max - min)) + min;
	}
	for (var i = 0; i < range; i++) {
		pos = Math.round(Math.random() * (arr.length - 1));
		str += arr[pos];
	}
	return str;
}

// 判断是否微信内置浏览器
export function isWeiXin() {
	return navigator.userAgent.toLowerCase().match(/MicroMessenger/i) == 'micromessenger';
}

// 获取手机品牌
export function phoneBrand() {
	let sUserAgent = navigator.userAgent.toLowerCase()
	// console.log(sUserAgent);
	var isIphone = sUserAgent.match(/iphone/i) == "iphone";
	var isHuawei = sUserAgent.match(/huawei/i) == "huawei";
	var isHonor = sUserAgent.match(/honor/i) == "honor";
	var isOppo = sUserAgent.match(/oppo/i) == "oppo";
	var isOppoR15 = sUserAgent.match(/pacm00/i) == "pacm00";
	var isVivo = sUserAgent.match(/vivo/i) == "vivo";
	var isXiaomi = sUserAgent.match(/mi\s/i) == "mi ";
	var isXiaomi2s = sUserAgent.match(/mix\s/i) == "mix ";
	var isRedmi = sUserAgent.match(/redmi/i) == "redmi";
	var isSamsung = sUserAgent.match(/sm-/i) == "sm-";

	if (isIphone) {
		return 'iphone';
	} else if (isHuawei || isHonor) {
		return 'huawei';
	} else if (isOppo || isOppoR15) {
		return 'oppo';
	} else if (isVivo) {
		return 'vivo';
	} else if (isXiaomi || isRedmi || isXiaomi2s) {
		return 'xiaomi';
	} else if (isSamsung) {
		return 'samsung';
	} else {
		return 'default';
	}
}

// 获取移动设备型号、移动设备系统、设备系统版本
export function getSystemAndModel() {
	//判断数组中是否包含某字符串
	Array.prototype.contains = function(needle) {
		for (i in this) {
			if (this[i].indexOf(needle) > 0)
				return i;
		}
		return -1;
	}
	// 引入 mobile-detect.js 插件，用于获取: 设备系统、系统版本、设备型号
	// 引入前先安装: npm i mobile-detect --save
	const MobileDetect = require('mobile-detect');

	const device_type = navigator.userAgent; // 获取userAgent信息
	const md = new MobileDetect(device_type); // 初始化mobile-detect

	let os = md.os(); // 设备系统: AndroidOS、iOS...
	let version = ""; // 系统版本
	let model = ""; // 设备型号

	if (os == "iOS") { // ios系统
		model = md.mobile(); // 设备型号: iPhone、iPad
		version = md.version(model); // 版本号: 11、13.23
	} else if (os == "AndroidOS") { // Android系统
		version = md.version("Android"); // 版本号: 11、13.23

		// console.log(md.mobile()); // 部分Android设备型号: UnknownPhone

		// 获取型号
		var sss = device_type.split(";");
		var i = sss.contains("Build/");
		if (i > -1) {
			model = sss[i].substring(0, sss[i].indexOf("Build/")).trim();
		}
	}
	// console.log('设备系统: ' + os + ", 设备型号: " + model);
	return {
		os,
		version,
		model
	}
}
