const CryptoJS = require('./aes.js'); //引用AES源码js

const key = CryptoJS.enc.Utf8.parse("6422e456c23d4769"); //十六位十六进制数作为秘钥
const iv = CryptoJS.enc.Utf8.parse('5e8y6w45ju8w9j67');//十六位十六进制数作为秘钥偏移量

// aes 加密方法
function AesEncrypt(word) {
  let srcs = CryptoJS.enc.Utf8.parse(word);
  let encrypted = CryptoJS.AES.encrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC, // 加密模式: CBC
    padding: CryptoJS.pad.Pkcs7 // 偏移模式(填充模式): Pkcs7
  });
  return encrypted.ciphertext.toString().toUpperCase();
}

// aes 解密方法
function AesDecrypt(word) {
  let encryptedHexStr = CryptoJS.enc.Hex.parse(word); // 输出模式: Hex
  let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
  let decrypt = CryptoJS.AES.decrypt(srcs, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC, // 加密模式: CBC
    padding: CryptoJS.pad.Pkcs7 // 偏移模式(填充模式): Pkcs7
  });
  let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
  return decryptedStr.toString();
}

// base64 加密方法
function Base64Encode(val) {
  let str = CryptoJS.enc.Utf8.parse(val);
  let base64 = CryptoJS.enc.Base64.stringify(str);
  return base64;
}

// base64 解密方法
function Base64Decode(val) {
  let words = CryptoJS.enc.Base64.parse(val); // 输出模式: Base64
  return words.toString(CryptoJS.enc.Utf8);
}

export default {
  AesEncrypt,
  AesDecrypt,
  Base64Encode,
  Base64Decode
}