// export const ERROR_MESSAGE_MAPS = {
export default {
  zh: {
    // DEFAULT: '接口请求失败 Request Failed',
    // OFFLINE: '网络连接断开 Network is Offline',
    400: '请求错误, 请检查参数 Bad Request',
    401: '未授权 Unauthorized',
    403: '禁止访问 Forbidden',
    404: '资源不存在 Not Found',
    405: '请求方式不允许 Method Not Allowed',
		408: '请求超时 Request Timeout',
    413: '资源过大 Payload Too Large',
    414: 'URI过长 URI Too Long',
    500: '服务器内部错误 Internal Server Error',
    502: '网关错误 Bad Gateway',
		503: '服务不可用 Service Unavailable',
    504: '网关超时 Gateway Timeout',
  },
  en: {
    // DEFAULT: 'Request Failed',
    // OFFLINE: 'Network is Offline',
    400: 'Bad Request',
    401: 'Unauthorized',
    403: 'Forbidden',
    404: 'Not Found',
    405: 'Method Not Allowed',
		408: 'Request Timeout',
    413: 'Payload Too Large',
    414: 'URI Too Long',
    500: 'Internal Server Error',
    502: 'Bad Gateway',
		503: 'Service Unavailable',
    504: 'Gateway Timeout',
  },
};